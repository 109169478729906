.button {
    cursor: pointer;
    font: inherit;
    border: none;
    background-color:  #6e14dc;
    color: rgb(0, 0, 0);
    padding: 0.75rem 3rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 25px;
    font-weight: bold;
  }
  
  .button:hover,
  .button:active {
    background-color: #0c2793;
  }
  
  .icon {
    width: 1.35rem;
    height: 1.35rem;
    margin-right: 0.5rem;
    color: rgb(250, 248, 248);
  }
  .yourcart{
    color: rgb(250, 248, 248);
  }
  .badge {
    background-color: #ffffff;
    padding: 0.25rem 1rem;
    border-radius: 25px;
    margin-left: 1rem;
    font-weight: bold;
  }
  
  .button:hover .badge,
  .button:active .badge {
    background-color: #ffffff;
  }
  
  .bump {
    animation: bump 300ms ease-out;
  }
  
  @keyframes bump {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(0.9);
    }
    30% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1.15);
    }
    100% {
      transform: scale(1);
    }
  }